import React from "react";
import { Icon } from "@iconify/react";
import "../FormSuccess/FormSuccess.css";
import { Container } from "react-bootstrap";

const FormSuccess = ({phone}) => {
  return (
    <section className="section-spacing">
      <Container>
        <div className="text-center">
          <Icon icon="simple-line-icons:check" className="successCheck" />
          <div className="section-title">
            <h2 className="text-center">Form Submitted Successfully</h2>
            <p className="title-desc-primary text-center">
              One of our representatives will call your shortly.
            </p>
        
            <p className="title-desc-primary text-center">
                A confirmation SMS has been sent to {phone}
            </p>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FormSuccess;
