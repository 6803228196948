import React from "react";
import OfferForm from "../../components/OfferForm/OfferForm";
import PromotionHeader from "../../components/PromotionHeader/PromotionHeader";

import OfferBanner from "../../images/ramadan-buzz-offers.png"
import PromotionFooter from "../../components/PromotionFooter/PromotionFooter";

const RamadanOffer = () => {
  return (
    <div>
      <PromotionHeader />
      <div className="offersBannerContent">
        <div className="offersBanner">
          <img src={OfferBanner} />
          <div className="offersBannerCaption">
            <h1>Ramadan Buzz Offer</h1>
            <h1>2024 Offer</h1>
            <p>Offer valid till 31st March 2024</p>
          </div>
        </div>
        <OfferForm />
        <PromotionFooter/>
      </div>
    </div>
  );
};

export default RamadanOffer;
