import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import AllOffers from "./pages/AllOffers/AllOffers";
import Footer from "./components/Footer/Footer";
import OfferDetails from "./components/OfferDetails/OfferDetails";
import RamadanOffer from "./pages/RamadanOffer/RamadanOffer";
import { LanguageProvider } from "./LanguageContext";
import AboutUs from "./pages/AboutUs/AboutUs";

function App() {

  const [language, setLanguage] = useState('en'); // Default language is English
  console.log(language)

  return (
    <BrowserRouter>
      <LanguageProvider value={{ language, setLanguage }}>
        {/* <div>
          <Routes>
            <Route path="ramadan-offer" element={<RamadanOffer />} />
          </Routes>
        </div> */}
        <main>
          {/* <Header /> */}
          <Routes>
            {/* <Route index element={<Home />} />
            <Route path="all-offers" element={<AllOffers />} />
            <Route path="offer-details/:id" element={<OfferDetails />} />
            <Route path="about-us" element={<AboutUs />} /> */}
            <Route index element={<RamadanOffer />} />
          </Routes>
          {/* <Footer /> */}
        </main>
      </LanguageProvider>
    </BrowserRouter>
  );
}

export default App;


