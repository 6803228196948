import React from "react";
import Logo from "../../images/Logo.png";

const PromotionFooter = () => {
  return (
    <footer className="section-spacing">
      <div className="text-center">
        <img className="mb-5" src={Logo} />
        <div className="footer-credit">
          <p>Copyright &copy; 2024 Watani Auto</p>
          <p>All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default PromotionFooter;
