import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import FormSuccess from "../FormSuccess/FormSuccess";
import { useParams } from "react-router-dom";
import axios from "axios";

const OfferForm = () => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    city: "",
    car_brand: "",
    car_model: "",
    idNumber: "",
    
    payment: "cash",
    offerName: id,
    comment: ""
  });

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, payment: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation
    const errors = {};
    if (formData.name.length < 3 || !/^[a-z A-Z ]+$/.test(formData.name)) {
      errors.name =
        "Full Name is required and must be at least 3 characters without numbers";
    }
    
    if (!formData.phone || !/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone Number is required and must be 10 digits";
    }
    if (!formData.idNumber || !/^\d{10}$/.test(formData.idNumber)) {
      errors.idNumber =
        "Identification Number is required and must be 10 digits";
    }
    if (!formData.city) {
      errors.city = "City is required";
    }
    if (!formData.car_brand) {
      errors.car_brand = "Brand is required";
    }
    if (!formData.car_model) {
      errors.car_model = "Model is required";
    }
    
    if (!formData.payment) {
      errors.payment = "Payment Method is required";
    }
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      setLoading(true); // Set loading state to true
      console.log("Form submitted:", formData);

      // Make POST request using Axios
      axios
        .post("https://napi.nhcops.com/offers", formData)
        .then((response) => {
          console.log("Form submission response:", response);
          setFormSubmitted(true);
          setLoading(false); // Set loading state to false after submission

          // Reset form data after successful submission
          setTimeout(() => {
            setFormSubmitted(false);
            setFormData({
              name: "",
              phone: "",
              email: "",
              city: "",
              car_brand: "",
              car_model: "",
              idNumber: "",
              payment: "cash",
              offerName: id,
              comment: ""
            });
          }, 3000);
        })
        .catch((error) => {
          console.error("Form submission error:", error);
          // Handle error if form submission fails

          setLoading(false); // Set loading state to false if submission fails
          // Handle error if form submission fails
        });
    }
  };

  return (
    <section className="section-spacing">
      {!formSubmitted && (
        <Container>
          <div className="section-title">
            <h2 className="text-center">Get in Touch</h2>
            <p className="title-desc-primary text-center">
              Fill the form below to get in touch with one of our
              representatives
            </p>
          </div>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="name">
                  <Form.Control
                    type="text"
                    placeholder="Enter your full name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    isInvalid={!!errors.name}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    // isInvalid={!!errors.email}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="idNumber">
                  <Form.Control
                    type="tel"
                    placeholder="Enter your Id Number"
                    name="idNumber"
                    value={formData.idNumber}
                    onChange={handleChange}
                    isInvalid={!!errors.idNumber}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.idNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Control
                    type="tel"
                    placeholder="Enter your Contact Number"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={4}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Select
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                    className="brand-input"
                  >
                    <option value="">Select City</option>
                    <option value="Riyadh">Riyadh</option>
                    <option value="Jeddah">Jeddah</option>
                    <option value="Makkah">Makkah</option>
                    <option value="Medinah">Medinah</option>
                    <option value="Dammam">Dammam</option>
                    <option value="Taif">Taif</option>
                    <option value="Tabuk">Tabuk</option>
                    <option value="Al Kharj">Al Kharj</option>
                    <option value="Buraydah">Buraydah</option>
                    <option value="Khamis Mushayat">Khamis Mushayat</option>
                    <option value="Haft Al Baten">Haft Al Baten</option>
                    <option value="Jizan">Jizan</option>
                    <option value="Hail">Hail</option>
                    <option value="Najran">Najran</option>
                    <option value="Jubail">Jubail</option>
                    <option value="Abha">Abha</option>
                    <option value="Yanbu">Yanbu</option>
                    <option value="Khobar">Khobar</option>
                    <option value="Onaizah">Onaizah</option>
                    <option value="Qatif">Qatif</option>
                    <option value="Rabigh">Rabigh</option>
                    <option value="Qassim">Qassim</option>
                    <option value="Al Baha">Al Baha</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="car_brand">
                  <Form.Select
                    name="car_brand"
                    value={formData.car_brand}
                    onChange={handleChange}
                    isInvalid={!!errors.car_brand}
                    className="brand-input"
                  >
                    <option value="">Select Brand</option>
                    <option value="Changan">Changan</option>
                    <option value="Hyundai">Hyundai</option>
                    <option value="Bestune">Bestune</option>
                    <option value="MG">MG</option>
                    <option value="Suzuki">Suzuki</option>
                    <option value="Kia">Kia</option>
                    <option value="Chevrolet">Chevrolet</option>
                    <option value="GAC">GAC</option>
                    <option value="Toyota">Toyota</option>
                    <option value="Haval">Haval</option>
                    <option value="Cherry">Cherry</option>
                    <option value="Jetour">Jetour</option>
                    <option value="Geely">Geely</option>
                    <option value="Renault">Renault</option>
                    <option value="Nissan">Nissan</option>
                    <option value="Ford">Ford</option>
                    <option value="Isuzu">Isuzu</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.car_brand}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={6}>
                {/* <Form.Group className="mb-3" controlId="model">
                  <Form.Select
                    name="model"
                    value={formData.model}
                    onChange={handleChange}
                    isInvalid={!!errors.model}
                    className="brand-input"
                  >
                    <option value="">Select Model</option>
                    <option value="model1">Model 1</option>
                    <option value="model2">Model 2</option>
                    <option value="model3">Model 3</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.model}
                  </Form.Control.Feedback>
                </Form.Group> */}

                <Form.Group className="mb-3" controlId="car_model">
                  <Form.Control
                    type="text"
                    placeholder="Enter Car Model"
                    name="car_model"
                    value={formData.car_model}
                    onChange={handleChange}
                    isInvalid={!!errors.car_model}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.car_model}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="comment">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your comment"
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}n
                    // isInvalid={!!errors.comment}
                    className="brand-input"
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.comment}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={12} md={6} lg={6}>
                <Form.Group className="mb-3" controlId="payment">
                  <Form.Check
                    inline
                    type="radio"
                    label="Cash"
                    name="payment"
                    value="cash"
                    checked={formData.payment === "cash"}
                    onChange={handleRadioChange}
                    isInvalid={!!errors.payment}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="Installments"
                    name="payment"
                    value="installments"
                    checked={formData.payment === "installments"}
                    onChange={handleRadioChange}
                    isInvalid={!!errors.payment}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payment}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button className="brand-btn" type="submit" disabled={loading}>
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Submitting...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      )}
      {formSubmitted && <FormSuccess phone={formData.phone} />}
    </section>
  );
};

export default OfferForm;
