import React from "react";
import Logo from "../../images/Logo.png";
import '../PromotionHeader/PromotionHeader.css'

const PromotionHeader = () => {
  return (
    <header className="p-2">
      <div className="promotional-header">
        <img src={Logo} />
      </div>
    </header>
  );
};

export default PromotionHeader;
